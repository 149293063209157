<template>

  <section class="forms">
    <div class="page-header">
      <h3 class="page-title">
        Import Transactions
      </h3>
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><a href="javascript:void(0);">Transactions</a></li>
          <li class="breadcrumb-item active" aria-current="page">Import</li>
        </ol>
      </nav>
    </div>
    <ValidationObserver v-slot="{ handleSubmit, invalid }" ref="form">
    <form @submit.prevent="handleSubmit(onSubmit)" class="forms-sample">
    <div class="row">
      <div class="col-md-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title">Transactions File</h4>
            <p class="card-description">
              please upload a transactions .xlsx file.
            </p>
            <div class="row">
              <div class="col-md-6">
                <ValidationProvider name="title" rules="required">
                 <b-form-group slot-scope="{ errors }" label="Title" label-for="title">
                   <b-form-input type="text" id="title" v-model="title" placeholder="Title"></b-form-input>
                   <p>{{ errors[0] }}</p>
                 </b-form-group>
               </ValidationProvider>
               <ValidationProvider name="classification" rules="required">
                 <b-form-group slot-scope="{ errors }" label-for="classification" horizontal label="Classification">
                   <b-form-select id="classification" @change="switchSubClass" v-model="dataClassification" :options="classifications"/>
                   <p>{{ errors[0] }}</p>
                 </b-form-group>
               </ValidationProvider>
              </div>
              <div class="col-md-6">
                <ValidationProvider name="transactions" rules="required">
                  <b-form-group slot-scope="{ errors }" label="Import File" label-for="transactions">
                    <b-form-file v-model="file" multiple accept=".xlsx" id="transactions" :state="Boolean(file)" placeholder="Choose a file..."></b-form-file>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
                <ValidationProvider name="sub_classification" rules="required">
                  <b-form-group slot-scope="{ errors }" label-for="sub_classification" horizontal label="Sub Classification">
                    <b-form-select id="sub_classification" :disabled="!subClassifications.length" v-model="classification_id" :options="subClassifications"/>
                    <p>{{ errors[0] }}</p>
                  </b-form-group>
                </ValidationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 grid-margin stretch-card">
        <div class="card">
          <div class="card-body">
            <b-form-group class="float-right">
              <b-button type="submit" variant="success" :disabled="invalid" class="mr-2">{{ create_text }}</b-button>
              <b-button type="button" @click="resetForm" variant="light">Clear</b-button>
            </b-form-group>
          </div>
        </div>
      </div>
    </div>
    </form>
    </ValidationObserver>
    <vue-snotify></vue-snotify>
  </section>

</template>

<script lang="js">
/* eslint-disable no-debugger, no-console */

import { ValidationObserver } from 'vee-validate'

export default {
  name: 'transactionAddNew',
  data () {
    return {
      file: null,
      title: "",
      create_text: "Import Transactions",
      classification_id: "",
      dataClassification: "",
      dataSubClassification: "",
      classifications: [
        {value: "", text: "Select Classification"},
        {value: "Commercial", text: "Commercial"},
        {value: "Residential", text: "Residential"},
      ],
      subClassifications: []
    }
  },
  components: {
    ValidationObserver
  },
  computed: {
    listSubClassifications() {
      return this.$store.getters['plot/listSubClassification']
    },
    commercialClassifications() {
      return this.$store.getters['plot/listCommercialClassification']
    },
    residentialClassifications() {
      return this.$store.getters['plot/listResidentialClassification']
    }
  },
  methods: {
    onSubmit() {
      this.create_text = "Importing..."
      let form_data = new FormData()

      //iterate and append
      this.file.forEach((data) => {
        form_data.append('files[]', data, data.name)
      })

      form_data.append('import_title', this.title)
      form_data.append('classification_id', this.classification_id)

      this.$store.dispatch('plan/importPlan', form_data)
      .then((res) => {
        this.create_text = "Import Plan"
        this.resetForm()
        this.$snotify.success(res.data.message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
      .catch((error) => {
        this.create_text = "Import Plan"
        let message = "Something went wrong!"
        if(error.message)
          message = error.response.data.message

        this.$snotify.warning(message, {
          timeout: 2000,
          showProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true
        })
      })
    },
    resetForm() {
      this.file = null
      this.title = ""
      this.dataClassification = ""
      this.dataSubClassification = ""
      this.$nextTick(() => {
        this.$refs.form.reset();
      })
    },
    switchSubClass() {
      if(this.dataClassification === "")
        this.subClassifications = []
      else if(this.dataClassification === "Commercial") {
        this.subClassifications = this.commercialClassifications.map(com => {
          return {
            value: com.id,
            text: com.name
          }
        })
      }
      else if(this.dataClassification === "Residential") {
        this.subClassifications = this.residentialClassifications.map(res => {
          return {
            value: res.id,
            text: res.name
          }
        })
      }

    },
    fetchSubClassifications() {
      const payload = {
        meta: {
          page: this.currentPage,
          perPage: this.paginationPageSize
        },
      }
      this.$store.dispatch("plot/fetchSubClassifications", payload)
    }
  },
  created() {
    this.fetchSubClassifications()
  }
}
</script>
